import {Routes, Route} from 'react-router-dom';
import Navbar from './components/NavBar';
import Top from './components/Top';
import Home from './components/pages/Home';
import CarouselHome from './components/CarouselHome';
import OurWorks from './components/pages/OurWorks';
import Footer from './components/Footer';
import About from './components/pages/About';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsOfService from './components/pages/TermsOfService ';
import Gallery from './components/pages/Gallery';
import {dataGallery} from './data/dataGallery';
import { FloatingWhatsApp } from 'react-floating-whatsapp'


const App = () => {
  return (
    <div>
      <Top />
      <Navbar /> {/* Navbar com os links */}
      <CarouselHome />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/works' element={<OurWorks />} />
        <Route path='/gallery' element={<Gallery images={dataGallery} />} />
        <Route path='/about' element={<About />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
        <Route path='/terms' element={<TermsOfService />} />
        <Route path='*' element={<Home />} />
      </Routes>
      <FloatingWhatsApp accountName='GB Flooring LLC' avatar='./image.png' phoneNumber='+12407011494' onSubmit={()=>{window.dataLayer.push({event:'whatsapp'})}} />
      <Footer />
    </div>
  );
};

export default App;
