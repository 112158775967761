import {Box, Typography, Container} from '@mui/material';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import BoxSectionContainer from '../BoxSectionContainer';

const TermsOfService = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <BoxSectionContainer title='Terms of Service'>
      <Container maxWidth='md' sx={{mt: 4, mb: 4}}>
        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            1. Agreement to Terms
          </Typography>
          <Typography>
            By accessing and using our website (http://www.gbflooringllc.com)
            and the services we offer, you agree to comply with these Terms of
            Service. If you do not agree with any part of these terms, please
            refrain from using our site.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            2. Services
          </Typography>
          <Typography>
            We provide information and services related to flooring
            installations. While we strive to ensure accuracy, all information
            provided on this site is subject to change without notice. We
            reserve the right to modify, suspend, or discontinue any aspect of
            our services at any time.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            3. User Responsibilities
          </Typography>

          <Box mb={2}>
            <Typography variant='subtitle1' gutterBottom>
              3.1 Eligibility
            </Typography>
            <Typography>
              By using our site, you confirm that you are at least 18 years old
              or have the permission of a legal guardian.
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography variant='subtitle1' gutterBottom>
              3.2 Account Information
            </Typography>
            <Typography>
              To request a quote or certain services, you may need to provide
              personal information. You are responsible for maintaining accurate
              and current information. Unauthorized access to your account or
              data is prohibited.
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography variant='subtitle1' gutterBottom>
              3.3 Prohibited Conduct
            </Typography>
            <Typography>Users agree not to:</Typography>
            <Typography component='ul'>
              <li>
                Violate any applicable local, state, national, or international
                law;
              </li>
              <li>
                Impersonate any individual or entity or falsely represent your
                affiliation;
              </li>
              <li>
                Interfere with or disrupt our website's operation or security.
              </li>
            </Typography>
          </Box>
        </Box>

        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            4. Intellectual Property
          </Typography>
          <Typography>
            All content on this site, including text, images, logos, and
            designs, is owned by or licensed to us. Unauthorized use,
            reproduction, or distribution of any content is prohibited unless
            expressly permitted by us.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            5. Limitation of Liability
          </Typography>
          <Typography>
            To the extent permitted by law, our company is not liable for any
            indirect, incidental, special, or consequential damages arising from
            or related to your use of our site and services. We do not guarantee
            that our website will be error-free, uninterrupted, or that any
            defects will be corrected.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            6. Links to Third-Party Websites
          </Typography>
          <Typography>
            Our site may include links to external websites for reference. We do
            not control or endorse these sites and are not responsible for their
            content or practices.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            7. Changes to Terms
          </Typography>
          <Typography>
            We reserve the right to modify these Terms of Service at any time.
            Any updates will be posted on this page, and continued use of our
            site constitutes acceptance of the revised terms.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            8. Termination
          </Typography>
          <Typography>
            We reserve the right to restrict or terminate access to our site or
            services at any time if a user violates these Terms of Service or
            for any other reason deemed necessary.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            9. Governing Law
          </Typography>
          <Typography>
            These Terms of Service are governed by and construed in accordance
            with the laws.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant='h6' gutterBottom>
            10. Contact Us
          </Typography>
          <Typography>
            If you have any questions or concerns regarding these Terms of
            Service, please contact us at: <a href='contact@gbflooring.com'>contact@gbflooring.com</a>.
          </Typography>
        </Box>
      </Container>
    </BoxSectionContainer>
  );
};

export default TermsOfService;
