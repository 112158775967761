import {Box, List, ListItem, ListItemText} from '@mui/material';


const SearchResultsList = ({items}: {items: {name: string; path: string}[]}) => {
  return (
    <List>
      {items.map((item: {name: string; path: string}, index: number) => (
        <ListItem key={index}>
          {/* Usando o Link do react-router-dom para navegação SPA */}
          <Box
            component={'a'}
            href={item.path}
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              '&:hover': {color: '#808080'},
            }}
          >
            <ListItemText primary={item.name} />
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default SearchResultsList;
