import { Box } from "@mui/material";

const BoxSection = ({
  children,
  backgroundColor,
  padding,
}: {
  children: React.ReactNode;
  backgroundColor?: string;
  padding?: string;
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {xs: '1fr', md: '1fr 1fr'},
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: {xs: 'column nowrap', md: 'row nowrap'},
        backgroundColor: backgroundColor,
        padding: padding,
      }}
    >
      {children}
    </Box>
  );
};

export default BoxSection;