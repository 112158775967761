import {Box, Typography} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import CustomButtonContact from './CustomButtonContact';
import {dataNewserviceHomeCarousel} from '../data/dataNewserviceHomeCarousel';

const CarouselHome = () => {
  return (
    <Carousel
      animation='slide'
      indicators
      navButtonsAlwaysVisible
      indicatorIconButtonProps={{
          style: {
            zIndex: 2,
          }
      }}
      indicatorContainerProps={{
        style: {
          paddingBottom: '10px',
          marginTop: '-35px', // 5
          textAlign: 'center', // 4
        },
      }}
    >
      {dataNewserviceHomeCarousel.map((item, i) => (
        <Box
          key={i}
          sx={{
            textAlign: 'center',
            backgroundImage: `url(${item.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            color: '#fff',
            height: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '600px',
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 2 }}>
              {item.name}
            </Typography>
            <Typography variant='body1' sx={{ mb: 4 }}>
              {item.description}
            </Typography>
            <CustomButtonContact />
          </Box>
        </Box>
      ))}
    </Carousel>
  );
};

export default CarouselHome;
