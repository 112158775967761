import {Box, Typography} from '@mui/material';
import BoxSectionContainer from '../BoxSectionContainer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const About = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <BoxSectionContainer title='About'>
      <Box sx={{p: 6}}>
        <Typography
          component={'p'}
          sx={{textAlign: 'justify', padding: {xs: 0, sm: 2, md: '0 80px', lg: '0 350px'}, mb: 6}}
        >
          Established in 2017, GB Flooring LLC was founded with a mission to
          transform spaces and restore the beauty of homes, offering specialized
          services in flooring installation and renovation. Our commitment goes
          beyond technical excellence: we are dedicated to making our clients'
          dreams come true by bringing comfort, beauty, and functionality to
          every space. With years of experience in the industry, GB Flooring LLC
          has built a solid reputation for delivering high-quality projects with
          attention to detail and respect for deadlines. Our team consists of
          highly skilled professionals, committed to providing the best service
          from material selection to project completion. We believe every home
          has its own story and unique potential, and it is our passion to
          uncover that, restoring and renewing each space with the care it
          deserves. Whether you’re renovating a space or looking for flawless
          installation, we are ready to bring the shine back to your home. GB
          Flooring LLC – Bringing the shine back to homes and dreams.
        </Typography>
      </Box>
    </BoxSectionContainer>
  );
};

export default About;
