import {TextField, Button, Box} from '@mui/material';
import {useState} from 'react';


window.dataLayer = window.dataLayer || [];

const inputStyle = {
  mb: 2,
  color: '#356172',
  '& .MuiOutlinedInput-root': {
    background: '#031820',
    '& fieldset': {
      borderColor: '#356172', // Cor da borda padrão
    },
    '&:hover fieldset': {
      borderColor: '#356172', // Cor da borda ao passar o mouse (hover)
    },
    '&.Mui-focused fieldset': {
      borderColor: '#356172', // Cor da borda quando focado
    },
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF', // Cor do texto dentro do input
  },
  '& .MuiInputLabel-root': {
    color: '#FFFFFF', // Cor do label padrão
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#FFFFFF', // Cor do label quando focado
  },
};

const FormHome = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        'https://backend-josue-production.up.railway.app/send',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      // const data = await response.json();
      if (response.ok) {
        // alert(data.message);
        setSuccess(true);
        setLoading(false);
        window.dataLayer.push({
          event: 'contact_form',
          email: formData.email,
          name: formData.name,
          subject: formData.subject,
          message: formData.message,
        })
      } else {
        setSuccess(false);
        setError('Error for sending email.');
        setLoading(false);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        const message: string = error.message;
        setError(message || 'Error for sending email.');
        setLoading(false);
      }
    }
  };

  return (
    <>
      {success ? (
        <Box sx={{textAlign: 'center', color: 'green'}}>
          <h2>Email sent successfully!</h2>
        </Box>
      ) : (
        <Box
          component='form'
          action=''
          method='post'
          onSubmit={handleSubmit}
          id='contact-form'
          sx={{
            backgroundColor: '#08232E',
            p: 4,
            borderRadius: 2,
            border: ' 1px solid #356172',
            mb: {
              xs: 4,
              md: 0,
            },
          }}
        >
          <Box
            display={'grid'}
            gridTemplateColumns={{xs: '1fr', md: '1fr 1fr'}}
            gap={1}
          >
            <TextField
              id='name'
              name='name'
              label='Your name'
              value={formData.name}
              onChange={handleChange}
              variant='outlined'
              fullWidth
              required
              sx={inputStyle}
            />
            <TextField
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              label='Your email'
              variant='outlined'
              type='email'
              fullWidth
              required
              sx={inputStyle}
            />
          </Box>
          <TextField
            id='subject'
            name='subject'
            value={formData.subject}
            onChange={handleChange}
            label='Subject'
            variant='outlined'
            type='text'
            fullWidth
            required
            sx={inputStyle}
          />
          <TextField
            id='Message'
            name='message'
            value={formData.message}
            onChange={handleChange}
            label='Message'
            variant='outlined'
            multiline
            rows={4}
            fullWidth
            required
            sx={inputStyle}
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {isLoading && <p>Loading...</p>}
          <Button type='submit' variant='contained' color='primary'>
            Enviar
          </Button>
        </Box>
      )}
    </>
  );
};

export default FormHome;
