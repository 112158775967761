import {Box, Typography} from '@mui/material';

const InfoCardItemNewService = ({
  items, onRef
}: {
  items: {
    imgSrc: string;
    title: string;
    description: string;
  }[];
  onRef?: (element: HTMLElement) => void;
}) => {
  return (
    <Box>
      {items.map(
        (
          item: {imgSrc: string; title: string; description: string},
          index: number
        ) => (
          <Box
            key={index}
            component={'div'}
            ref={onRef}
            id={
              item.title
                .trim()
                .toLowerCase()
                .replace(/\s+/g, '-') // Substitui espaços por hífens
                .replace(/[^a-z0-9-]/g, '') // Remove todos os caracteres que não sejam letras, números ou hífens
            }
            sx={{
              padding: '40px 0',
              display: 'flex',
              flexDirection:
                index % 2 === 0
                  ? {xs: 'column', md: 'row'}
                  : {xs: 'column', md: 'row-reverse'}, // Alterna layout (imagem à esquerda/direita)
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor:
                index % 2 === 0 ? 'rgba(137, 88, 6, 0.2)' : '#fff', // Alterna cores de fundo
            }}
          >
            <Box
              component={'div'}
              sx={{
                width: {xs: '300px', md: '400px', lg: '450px'},
                height: {xs: '300px', md: '400px', lg: '450px'},
                backgroundImage: `url(${item.imgSrc})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Box sx={{p: 7}} width={{xs: '100%', md: '50%'}}>
              <Typography component={'span'} sx={{fontWeight: 'bold'}}>
                {item.title}
              </Typography>
              <Typography component={'span'} sx={{ml: 2}}>
                {item.description}
              </Typography>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default InfoCardItemNewService;
