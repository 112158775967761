export const dataOurServiceFooter = [
  {
    title: 'Hardwood Flooring',
    path: '/works#hardwood-flooring',
  },
  {
    title: 'SUPPLIES FLOORING',
    path: '/works#vinyll-flooring',
  },
  {
    title: 'Laminate FLOORING',
    path: '/works#laminate-flooring',
  },
  {
    title: 'Vinyl FLOORING',
    path: '/works#flooring-supplies',
  },
  {
    title: 'CARPET',
    path: '/works#carpet',
  }
];

export const dataUsefulLinks = [
  {
    title: 'About us',
    path: '/about',
  },
  {
    title: 'Privacy policy',
    path: '/privacy',
  },
  {
    title: 'Terms of service',
    path: '/terms',
  },
  
]
