import {Box, Typography} from '@mui/material';
import CarouselHomePlank from '../CarouselHomePlank';
import BoxSectionContainer from '../BoxSectionContainer';
import {dataNewserviceHome} from '../../data/dataNewserviceHome';
import img1 from '../../assets/home-imgs/1.png';
import img2 from '../../assets/home-imgs/2.png';
import img3 from '../../assets/home-imgs/3.png';
import img4 from '../../assets/home-imgs/4.png';
import img5 from '../../assets/home-imgs/5.png';
import BoxSection from '../BoxSection';
import InfoCardItemNewService from '../InfoCardItemNewService';

const BoxItemImageText = ({
  img,
  textTitle,
  textDescription,
}: {
  img: string;
  textTitle: string;
  textDescription: string;
}) => {
  return (
    <Box
      component={'div'}
      sx={{
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
      }}
    >
      <Box
        component={'img'}
        src={img}
        alt='Logo site'
        sx={{
          p: 2,
          borderBottom: '3px solid #fff',
          maxWidth: '100px',
          width: '100%',
        }}
      />
      <Typography component={'p'} variant='h5' sx={{fontWeight: 'bold', mt: 2}}>
        {textTitle}
      </Typography>
      <Typography component={'p'} variant='caption'>
        {textDescription}
      </Typography>
    </Box>
  );
};

const Home = () => {
  return (
    <>
      <BoxSection backgroundColor='#d1d1d1' padding='40px 0'>
        <Box sx={{p: 6}}>
          <Typography variant='h4' sx={{fontWeight: 'bold', mb: 2}}>
            Explore Our Hardwood Collections
          </Typography>
          <Typography variant='body1'>
            Browse our wide selection of high-quality hardwood flooring options.
            From classic to contemporary, Woodcove Flooring offers a variety of
            styles, colors, and finishes to suit any décor. Find the perfect
            floor for your space today
          </Typography>
        </Box>
        <CarouselHomePlank />
      </BoxSection>
      <BoxSection>
        <Box
          sx={{
            height: '500px',
            maxWidth: {xs: '100%', md: '600px'},
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row nowrap',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              p: 2,
            }}
          >
            {/* Primeiro Box com a primeira imagem */}
            <Box
              sx={{
                maxWidth: '200px',
                width: '100%',
                height: '300px', // Ajuste conforme o tamanho desejado
                backgroundImage: `url(${img1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                boxShadow: '0 0 5px 1px rgba(0, 0, 0, 0.7)',
                left: 0,
                top: 50,
              }}
            />

            {/* Box com a segunda imagem e o texto */}
            <Box
              sx={{
                maxWidth: '250px',
                width: '100%',
                height: '400px', // Ajuste conforme o tamanho desejado
                backgroundImage: `url(${img2})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                boxShadow: '0 0 5px 1px rgba(0, 0, 0, 0.7)',
                ml: -6,
              }}
            ></Box>
            <Box
              sx={{
                background: '#083343',
                color: '#fff',
                p: 2,
                textAlign: 'center',
                borderRadius: '3px',
                boxShadow: '0 0 5px 1px rgba(0, 0, 0, 0.7)',
                ml: -10,
              }}
            >
              <Typography variant='inherit'>
                Company Of The <br />
                Year Award <br />
                Winner
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{p: 6}}>
          <Typography variant='h4' sx={{fontWeight: 'bold', mb: 2}}>
            A Trendy Woodwork for Trendy Home
          </Typography>
          <Typography variant='body1'>
            We are committed to providing our customers with super exceptional
            service while offering our employees the best training and a working
            environment in which they can excel best of all places.
          </Typography>
          {/* <Button
            component={Link}
            to='/contact'
            variant='contained'
            sx={{mt: 2, background: '#083343'}}
          >
            Read More
          </Button> */}
        </Box>
      </BoxSection>
      <Box
        component={'div'}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: {xs: '400px', md: 'calc(300px)'},
        }}
      >
        <Box
          component={'div'}
          sx={{
            backgroundImage: `url(${img3})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
            filter: 'brightness(50%)',
            position: 'absolute',
          }}
        ></Box>
        <Box
          component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexFlow: {xs: 'column nowrap', md: 'row nowrap'},
            position: 'relative',
            color: '#fff',
          }}
        >
          <BoxItemImageText
            img={img5}
            textTitle='500+'
            textDescription='APARTMENTS'
          />
          <BoxItemImageText
            img={img4}
            textTitle='350+'
            textDescription='COMMERCIAL PROJECTS'
          />
        </Box>
      </Box>
      <BoxSectionContainer title='A new of our services include'>
        <InfoCardItemNewService items={dataNewserviceHome} />
      </BoxSectionContainer>
    </>
  );
};

export default Home;
