import {Box, Typography} from '@mui/material';
import logo from '../assets/logo-top.png';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {dataOurServiceFooter, dataUsefulLinks} from '../data/dataFooter';

import {Link} from 'react-router-dom';
import BoxSection from './BoxSection';
import FormHome from './FormHome';

import img6 from '../assets/home-imgs/6.png';
import img7 from '../assets/home-imgs/7.png';
import img8 from '../assets/home-imgs/8.png';

const Icons = ({link, icon}: {link: string; icon: JSX.Element}) => {
  return (
    <Box
      component={Link}
      to={link}
      sx={{
        color: '#fff',
        pr: 1,
        cursor: 'pointer',
        transition: 'color 0.3s ease-in-out',
        '&:hover': {color: '#808080'},
      }}
    >
      {icon}
    </Box>
  );
};

const TitleFooter = ({text}: {text: string}) => {
  return (
    <Typography
      component={'p'}
      variant='subtitle1'
      sx={{color: '#fff', fontWeight: 'bold', mt: 2}}
    >
      {text}
    </Typography>
  );
};

const CustonLinkComponent = ({text, link}: {text: string; link: string}) => {
  return (
    <Typography
      component={Link}
      to={link}
      sx={{
        color: '#fff',
        fontSize: '12px',
        cursor: 'pointer',
        transition: 'color 0.3s ease-in-out',
        '&:hover': {color: '#808080'},
        textDecoration: 'none',
      }}
    >
      {text}
    </Typography>
  );
};

const Footer = () => {
  return (
    <>
      <BoxSection backgroundColor={'#000'} padding={'40px'}>
        <Box id='contact'>
          <Box sx={{color: '#fff'}}>
            <Typography
              component={'p'}
              variant='caption'
              sx={{fontWeight: 'bold', textAlign: 'center'}}
            >
              GET IN TOUCH
            </Typography>
            <Typography
              component={'p'}
              variant='h5'
              sx={{fontWeight: 'bold', textAlign: 'center', p: 2}}
            >
              Needs Help? <br />
              Let’s Get in Touch
            </Typography>
          </Box>
          <FormHome />
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component={'img'}
            sx={{maxWidth: '250px', width: '100%', ml: -6}}
            src={img6}
          />
          <Box
            component={'img'}
            sx={{maxWidth: '250px', width: '100%', mt: -6, mr: -5}}
            src={img7}
          />
          <Box
            component={'img'}
            sx={{maxWidth: '250px', width: '100%', mt: -6, ml: -6}}
            src={img8}
          />
        </Box>
      </BoxSection>
      <Box
        component={'footer'}
        p={4}
        sx={{
          backgroundColor: '#171717',
        }}
      >
        <Box component={'img'} src={logo}></Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {xs: '1fr', md: '1fr 1fr 1fr'},
            justifyItems: {xs: 'start', md: 'center'},
            padding: '20px 40px',
            gap: 3,
          }}
        >
          <Box>
            <Box>
              <TitleFooter text='ABOUT COMPANY' />
              <Typography
                component={'p'}
                sx={{color: '#fff', fontSize: '12px'}}
              >
                GB Flooring LLC specializes in highquality hardwood flooring,
                combining durability and elegance. With a team of experienced
                professionals, we transform your spaces with flooring solutions
                that enhance your environment and stand the test of time.
                Discover excellence in every detail with GB Flooring LLC.
              </Typography>
            </Box>
            <Box mt={2}>
              <Icons
                link='https://www.facebook.com/'
                icon={<FacebookRoundedIcon />}
              />
              <Icons
                link='https://www.instagram.com/'
                icon={<InstagramIcon />}
              />
              <Icons link='https://www.linkedin.com/' icon={<LinkedInIcon />} />
            </Box>
          </Box>
          <Box>
            <TitleFooter text='Our Services' />
            <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
              {dataOurServiceFooter.map((item, index) => (
                <CustonLinkComponent
                  key={index}
                  text={item.title
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                  link={item.path}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <TitleFooter text='Useful links' />
            <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
              {dataUsefulLinks.map((item, index) => (
                <CustonLinkComponent
                  key={index}
                  text={item.title}
                  link={item.path}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
