import {Box, Typography} from '@mui/material';
import SearchComponent from './SearchComponent';
import logoTop from '../assets/logo-top.png';
import {Link} from 'react-router-dom';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const CustonLinkComponent = ({
  text,
  link,
  icon,
}: {
  text: string;
  link: string;
  icon?: JSX.Element;
}) => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'color 0.3s ease-in-out',
        ml: 2,
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        color: '#000',
        '&:hover': {color: '#808080'},
      }}
      component={Link}
      to={link}
    >
      {icon}
      {text}
    </Typography>
  );
};

const Top = () => {
  return (
    <Box
      sx={{
        padding: '5px 20px',
        textAlign: 'center',
        alignItems: 'center',
        mt: 2,
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          md: '1fr 1fr 1fr',
        },
      }}
    >
      <Box justifySelf={'initial'} sx={{ display: {xs: 'none', md: 'block'}}}>
        <SearchComponent />
      </Box>
      <Box justifySelf={'center'}>
        <Box component={Link} to={'/'}>
          <Box component='img' src={logoTop} alt='Logo site' />
        </Box>
      </Box>
      <Box justifySelf={'end'}
        sx={{
          fontSize: '18px',          
          display: {xs: 'none', md: 'flex'},
          flexFlow: 'row nowrap',
        }}
      >
        <CustonLinkComponent
          link='tel:+2407011494'
          text='(+240) 701-1494'
          icon={<LocalPhoneIcon />}
        />
        <CustonLinkComponent
          link='mailto:contact@gbflooring.com'
          text='contact@gbflooring.com'
          icon={<EmailIcon />}
        />
      </Box>
    </Box>
  );
};

export default Top;
