import img1 from '../assets/gallery/1 (1).jpeg';
import img2 from '../assets/gallery/1 (2).jpeg';
import img3 from '../assets/gallery/1 (3).jpeg';
import img4 from '../assets/gallery/1 (4).jpeg';
import img5 from '../assets/gallery/1 (5).jpeg';
import img6 from '../assets/gallery/1 (6).jpeg';
import img7 from '../assets/gallery/1 (7).jpeg';
import img8 from '../assets/gallery/1 (8).jpeg';
import img9 from '../assets/gallery/1 (9).jpeg';
import img10 from '../assets/gallery/1 (10).jpeg';

export const dataGallery = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
];
